import * as React from "react";
import Layout from "../../components/layout";
import PastoralContent from "../../components/education/pastoralCareContent";
import heroImage from "../../assets/education/secondary-level-hero-image.jpg";
import BannerImage from "../../components/master/BannerImage";

const PastoralCarePage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="Pastoral Care" />
      <PastoralContent />
    </Layout>
  );
};

export default PastoralCarePage;

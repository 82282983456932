import React from "react";
import "./enrolmentContent.css";
import PageTitle from "../master/PageTittle";
import ContentWrapper from "../master/ContentWrapper";
import SideBar from "../master/SideBar";
import { educational } from "../const";

const PastoralContent = () => (
  <div className="container">
    <ContentWrapper sidebar={true} narrow={true}>
      <div className={"content"}>
        <PageTitle title="Pastoral Care" />

        <p>
          St Maroun’s is committed to a pastoral care program that addresses the
          needs of every student, physically, emotionally, spiritually, socially
          and academically.
        </p>

        <p>
          There is a dedicated pastoral care team that provides guidance,
          support and leadership to every student. Students in Primary School
          receive pastoral care from their class room teachers, while students
          in the secondary School attend home room each morning and a pastoral
          lesson each fortnight.
        </p>

        <p>Our Pastoral team is led by the Principal and consists of:</p>

        <ul>
          <li>Assistant Principal</li>
          <li>Heads of Year</li>
          <li>Counsellor</li>
        </ul>

        <p>
          The aim of the well being program is to develop students with
          Christian values including integrity, compassion and responsibility.
        </p>

        <PageTitle title="Chaplaincy" variant="h2" />
        <p>
          The chaplaincy program at the College supports the College to instil
          the College values. The chaplain is involved in regular services and
          activities at the College being accessible to all students and their
          families.
        </p>
      </div>
      <SideBar items={educational} title="Educational Excellence" />
    </ContentWrapper>
  </div>
);

export default PastoralContent;
